import { Component } from 'preact';
import StringWrapper from "../../../util/wrapper/StringWrapper";
import OpeningHoursContainer from "../../opening-hours/OpeningHoursContainer";
import { CARRIER_LOGO_URL } from "../../../constants/constants";
import ClassNames from "classnames";
import { isStoreOption } from "../../../util/AppUtils";
import { doGet } from "../../../util/NetworkUtils";

export default class MapMarker extends Component {

    constructor(props) {
        super(props);
        let opened = props.pickupLocation && props.pickupLocation.checked;
        this.state = {
            opened: opened,
            animated: true,
            imageAvailable: true,
            carrierLogoImage: ''
        };
        if (opened) {
            document.addEventListener('mousedown', this.handleClickOutside);
            document.addEventListener('touchstart', this.handleClickOutside);
        }
    }

    componentDidMount() {
        const {pickupLocation, config} = this.props;
        if (this.state.opened) {
            this.props.updateMapPosition(true);
        }

        this.loadCarrierLogo(CARRIER_LOGO_URL + pickupLocation.shippingOptions[0].carrier.name + ".svg", config);
    }


    loadCarrierLogo = (logoUrl, config) => {
        doGet(logoUrl, config)
            .then(res => {
                this.setState({
                    carrierLogoImage: !res || !res.data ? "" : `data:image/svg+xml;base64,${res.data}`,
                    imageAvailable: true
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    carrierLogoImage: ""
                });
            });
    };


    componentDidUpdate(prevProps, prevState) {
        const {updateMapPosition, pickupLocation} = this.props;
        const {opened} = this.state;

        if (opened && !prevState.opened) {
            updateMapPosition(true);
        }

        if (!prevProps.pickupLocation.checked && pickupLocation.checked) {
            this.setState({
                opened: true,
                animated: true
            });
            document.addEventListener('mousedown', this.handleClickOutside);
            document.addEventListener('touchstart', this.handleClickOutside);
        }

        if (prevProps.pickupLocation.checked && !pickupLocation.checked) {
            this.setState({
                opened: false,
                animated: false
            });
        }

        if (prevProps.pickupLocation.checked && pickupLocation.checked && (prevProps.pickupLocation.code !== pickupLocation.code)) {
            this.setState({
                opened: true,
                animated: true
            });
        }
    }

    onMarkerClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            opened: true
        });

        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchstart', this.handleClickOutside);
        this.props.onSelectPickupLocation(this.props.pickupLocation.code);
        this.scrollToPickupLocation();
    };

    scrollToPickupLocation = () => {
        const {pickupLocation} = this.props;
        pickupLocation && pickupLocation.ref && pickupLocation.ref.scrollIntoViewIfNeeded();
    };

    onMarkerTouch = (e) => {
        e.stopPropagation();
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleClickOutside = (event) => {
        const {pickupLocation} = this.props;

        let outsideTooltip = !this.wrapperRef || !this.wrapperRef.contains(event.target);
        let outsideListElement = !pickupLocation.ref || !pickupLocation.ref.contains(event.target);

        if (outsideTooltip && outsideListElement) {
            this.setState({
                opened: false,
                animated: false
            });

            document.removeEventListener('mousedown', this.handleClickOutside);
            document.removeEventListener('touchstart', this.handleClickOutside);
        }

    };

    getLogo = (carrierLogoImage) => {
        const {pickupLocation, isStorePickup, storeLogo} = this.props;

        if (isStorePickup || isStoreOption(pickupLocation)) {
            return storeLogo &&
                <img src={storeLogo}
                     className="tooltip__logo__source polyfill-object-fit-contain"
                     onError={this.handleImageError}
                />;
        }
        return carrierLogoImage &&
            <img src={carrierLogoImage}
                 className="tooltip__logo__source polyfill-object-fit-contain"
                 onError={this.handleImageError}
                 alt={pickupLocation.shippingOptions[0].carrier.name}
                 title={pickupLocation.shippingOptions[0].carrier.name}
            />;

    };

    handleImageError = () => {
        this.setState({
            imageAvailable: false
        });
    };

    render() {
        const {pickupLocation, storeLogo, setTooltipRef} = this.props;
        const {opened, animated, imageAvailable, carrierLogoImage} = this.state;
        const openingTimes = pickupLocation.openingTimes;

        let addressLine = [pickupLocation.address.street, pickupLocation.address.streetNumber, pickupLocation.address.streetNumberSuffix].join(' ');

        const isStore = isStoreOption(pickupLocation) && storeLogo;

        return (
            <div ref={this.setWrapperRef} className="map-marker">
                {
                    opened &&
                    <div ref={setTooltipRef} className="widget-tooltip tooltip--map">
                        <div className="paazl-delimiter">
                            <header className="tooltip__header">
                                <hgroup className="tooltip__titles">
                                    <StringWrapper Tag="h4"
                                                   additionalClassName="tooltip__title"
                                                   content={pickupLocation.name}
                                                   notranslate/>

                                    <StringWrapper content={addressLine}
                                                   additionalClassName="tooltip__address"
                                                   notranslate/>

                                </hgroup>

                                <figure className="tooltip__logo">
                                    {
                                        imageAvailable && this.getLogo(carrierLogoImage)
                                    }
                                </figure>
                            </header>

                            <OpeningHoursContainer openingTimes={openingTimes}/>
                        </div>
                    </div>
                }

                <div className="marker" onClick={this.onMarkerClick} onTouchStart={this.onMarkerTouch}>
                    <div className={ClassNames('pin', {animated, "default-marker": !isStore}, pickupLocation.checked && 'focused')}>
                        {
                            isStore && <img src={storeLogo} />
                        }
                    </div>

                    <div className='pulse'/>
                </div>
            </div>
        );
    }
}