import { Component } from 'preact';
import GoogleMapReact from 'google-map-react';
import MapMarker from "./MapMarker";
import {
    GOOGLE_API_KEY,
    GOOGLE_MAPS_API_KEY_URL,
    MAP_ZOOMS,
    METER_IN_DEGREE,
    TOOLTIP_TOP_MARGIN_IN_PIXEL
} from "../../../constants/constants";
import { doGet } from "../../../util/NetworkUtils";

export default class PickupLocationMapForm extends Component {

    static defaultProps = {
        open: false
    };

    constructor(props) {
        super(props);
        this.state = {
            googleMapsApiKey: null,
            mapLoaded: false,
            zoom: 15,
            size: {
                width: 700,
                height: 500
            },
            center: {
                lat: 50.449549,
                lng: 30.525376
            }
        };
    }

    componentDidMount() {
        const {config} = this.props;

        if (!window.PAAZL_CHECKOUT_WIDGET_API_URL) {
            doGet(GOOGLE_MAPS_API_KEY_URL, config)
                .then(response => {
                    this.setState({googleMapsApiKey: response.data});
                })
                .catch(error => {
                    console.error(error);
                    this.setState({googleMapsApiKey: GOOGLE_API_KEY});
                });
        } else {
            this.setState({googleMapsApiKey: GOOGLE_API_KEY});
        }

        window.addEventListener("resize", this.updateMapPosition);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateMapPosition);
    }

    componentDidUpdate(previousProps, previousState) {
        const {open, pickupLocations} = this.props;

        const modalOpened = !previousProps.open && open;
        const listChanged = pickupLocations && !this.equalsLists(pickupLocations, previousProps.pickupLocations);

        if (previousState.zoom !== this.state.zoom) {
            this.updateMapPosition(modalOpened || listChanged, pickupLocations);
        }
    }

    onMapLoaded = () => {
        this.setState({mapLoaded: true});
    };

    setTooltipRef = (node) => {
        this.tooltipRef = node;
    };

    updateMapPosition = (skipZoomRecalculation, pickupLocations) => {
        const {open, pickupLocations: propsPickupLocations} = this.props;
        const {center: oldCenter, zoom: oldZoom} = this.state;

        if (!pickupLocations) {
            pickupLocations = propsPickupLocations;
        }

        if (open) {
            let zoom = skipZoomRecalculation ? oldZoom : this.calculateZoom();

            let currentPoint = pickupLocations && pickupLocations.find(pickupLocation => pickupLocation.checked);
            let center = currentPoint ? this.calculateMapCenter(currentPoint, zoom) : oldCenter;

            this.setState({
                zoom,
                center
            });
        }
    };

    equalsLists = (primary, secondary) => {
        if (!primary || !secondary || primary.length !== secondary.length) {
            return false;
        }

        for (let i = 0; i < primary.length; i++) {
            if (primary[i].code !== secondary[i].code) {
                return false;
            }
        }

        return true;
    };

    calculateZoom = () => {
        let distance = this.haversine();
        let zoom = 20;
        while (MAP_ZOOMS[--zoom]) {
            if (distance < MAP_ZOOMS[zoom] * this.state.size.width * 0.8) {
                break;
            }
        }

        return zoom;
    };

    getBoundCoordinates = () => {
        const {pickupLocations} = this.props;

        if (pickupLocations) {
            let latArray = pickupLocations
                .filter(s => s.coordinates && s.coordinates.latitude)
                .map(s => s.coordinates.latitude);
            let lngArray = pickupLocations
                .filter(s => s.coordinates && s.coordinates.longitude)
                .map(s => s.coordinates.longitude);
            if (latArray && lngArray) {
                let maxLat = Math.max.apply(null, latArray);
                let minLat = Math.min.apply(null, latArray);
                let maxLng = Math.max.apply(null, lngArray);
                let minLng = Math.min.apply(null, lngArray);

                return {maxLat, minLat, maxLng, minLng};
            }
        }
    };

    toRadians = (degrees) => {
        return degrees / 57.2957795;
    };

    /**
     * Calculate distance between Latitude/Longitude points.
     * Detailed documentation: http://www.movable-type.co.uk/scripts/latlong.html
     *
     * @returns distance
     */
    haversine = () => {

        let coordinates = this.getBoundCoordinates();

        if (coordinates) {
            let maxLat = coordinates.maxLat;
            let minLat = coordinates.minLat;
            let maxLng = coordinates.maxLng;
            let minLng = coordinates.minLng;
            let dLat = this.toRadians(maxLat - minLat);
            let dLng = this.toRadians(maxLng - minLng);
            let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.toRadians(maxLat)) * Math.cos(this.toRadians(minLat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
            let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return 6371000 * c; //6371000 - radius of Earth in meters.
        }
    };

    calculateMapCenter = (pickupLocation, zoom) => {
        if (pickupLocation && pickupLocation.coordinates) {
            return {
                lat: pickupLocation.coordinates.latitude + this.calculateMarginTopDistance(zoom),
                lng: pickupLocation.coordinates.longitude
            };
        }
    };

    calculateMarginTopDistance = (zoom) => {
        if (zoom === -1 || !this.tooltipRef) {
            return 0;
        }

        const zoomMeters = MAP_ZOOMS[zoom];
        return METER_IN_DEGREE * zoomMeters * ((TOOLTIP_TOP_MARGIN_IN_PIXEL + this.tooltipRef.clientHeight) - (this.state.size.height / 2));
    };

    onMapChange = (mapProps) => {
        const {zoom, size, center} = mapProps;
        const {zoom: oldZoom, size: oldSize, center: oldCenter} = this.state;

        if (zoom !== 0 && zoom !== oldZoom ||
            oldSize.width !== size.width || oldSize.height !== size.height ||
            oldCenter.lat !== center.lat || oldCenter.lng !== center.lng) {

            this.setState({
                zoom,
                size,
                center
            });
        }
    };

    render() {
        const {pickupLocations, onSelectPickupLocation, open, simple, isStorePickup, storeLogo, config} = this.props;
        const {center, zoom, googleMapsApiKey, mapLoaded} = this.state;

        const containerClassName = simple ? "window__map-simple" : "window__map";

        return (
            <div className={containerClassName}>
                {
                    open && googleMapsApiKey &&
                    <div className="map-container">
                        <GoogleMapReact bootstrapURLKeys={{key: [googleMapsApiKey]}}
                                        center={center}
                                        onTilesLoaded={this.onMapLoaded}
                                        zoom={zoom}
                                        onChange={this.onMapChange}>
                            {
                                mapLoaded && pickupLocations && pickupLocations.length > 0 &&
                                pickupLocations
                                    .filter(pickupLocation => this.hasCoordinates(pickupLocation))
                                    .map(pickupLocation => {
                                        return <MapMarker lat={pickupLocation.coordinates.latitude}
                                                          lng={pickupLocation.coordinates.longitude}
                                                          pickupLocation={pickupLocation}
                                                          isStorePickup={isStorePickup}
                                                          storeLogo={storeLogo}
                                                          onSelectPickupLocation={onSelectPickupLocation}
                                                          updateMapPosition={this.updateMapPosition}
                                                          setTooltipRef={this.setTooltipRef}
                                                          config={config}/>;
                                    })
                            }

                        </GoogleMapReact>
                    </div>
                }
            </div>
        );
    }

    hasCoordinates(pickupLocation) {
        return pickupLocation.coordinates && pickupLocation.coordinates.latitude && pickupLocation.coordinates.longitude;
    }
}